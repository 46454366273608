import React from 'react'
import {
  Container,
  Section,
  Seo,
  Zoom,
} from '@components'
import { PageLayout } from '../../../components/site/layout/page-layout'
import { OfferForm } from '../../../components/site/offer'
import { SignupBanner, MitmAttackGreenImage } from '../../../components/site/product'

const offer = 'mitm_whitepaper'

const SignupPage = ({ location }) => {
  return (
    <PageLayout pathname={location.pathname}>
      
      <SignupBanner title="How to Prevent Mobile Man-in-the-Middle (MitM) Attacks" />

      <Section>
        <Container className="py-8 container-lg">
          <div className="flex flex-col items-center md:flex-row md:items-center">
            <div className="mx-8 md:w-5/12 md:mx-4">
              <p>
                The communication channel between apps and APIs presents a rich
                target for hackers via Man-in-the-Middle (MitM) attacks.
                Transport Level Security (TLS) alone is not sufficient to stop
                them since tools installed in the device can easily intercept
                encrypted communications.
              </p>
              <p>
                This free whitepaper provides an in-depth analysis of the
                techniques used by hackers and explains how certificate pinning
                can help thwart mobile MitM attacks. It also introduces methods
                to prevent hackers tampering with device environments.
              </p>
              <p>
                In addition, it shows how over-the-air updates can be employed
                to reduce the chance of customer service being interrupted.
                Certificate rotations can be handled instantly and cleanly,
                eliminating any need to update apps.
              </p>
            </div>
            <div className="mx-8 bg-gray-100 rounded-lg md:w-7/12 md:mx-4">
              <h2 className="mt-4 mb-0 text-center">Request Your Copy Now</h2>
              <OfferForm className="px-4" offer={offer} />
            </div>
          </div>
        </Container>
      </Section>

      <Section>
        <Container className="mb-8 container-lg">
          <div className="flex flex-col items-center md:flex-row md:items-start">
            <div className="mx-8 md:pt-8 md:w-3/5 md:mx-4">
              <Zoom>{MitmAttackGreenImage}</Zoom>
            </div>
            <div className="mx-8 md:w-2/5 md:mx-4">
              <h2 className="mt-0">Whitepaper Contents</h2>
              <ul>
                <li className="md:my-0">Introduction</li>
                <li className="md:my-0">Man in the Middle Attacks</li>
                <li className="md:my-0">TLS and Encrypted Traffic</li>
                <li className="md:my-0">The Chain of Trust</li>
                <li className="md:my-0">
                  Breaking Trust - Trust Store Poisoning
                </li>
                <li className="md:my-0">Breaking Trust - CA Breach</li>
                <li className="md:my-0">The Benefits of Pinning</li>
                <li className="md:my-0">
                  Public Key Pinning versus Certificate Pinning
                </li>
                <li className="md:my-0">Implementing Pinning</li>
                <li className="md:my-0">The Static Pinning Configurator</li>
                <li className="md:my-0">The Operational Risks of Pinning</li>
                <li className="md:my-0">
                  The Bad News - Pinning Can Be Bypassed in the Client
                </li>
                <li className="md:my-0">Pinning Bypass by App Repackaging</li>
                <li className="md:my-0">
                  Pinning Bypass Using a Hooking Framework
                </li>
                <li className="md:my-0">Certificate Transparency</li>
                <li className="md:my-0">
                  Dynamic Pinning Provides Easy Administration and Elimination
                  of Operational Risks
                </li>
                <li className="md:my-0">
                  The Final Piece in the Puzzle - How to Block Client-Side MitM
                  Attacks
                </li>
                <li className="md:my-0">
                  Approov: Complete MitM Protection with Assured Service
                  Continuity
                </li>
                <li className="md:my-0">Conclusion</li>
              </ul>
            </div>
          </div>
        </Container>
      </Section>
    </PageLayout>
  )
}

export default SignupPage
